import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import type { User } from "@retentionscript/db";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { BannerSlim, DeviceTypeProvider } from "gestalt";
import gestaltStyles from "gestalt/dist/gestalt.css?url";
import { useEffect } from "react";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import type { HoneypotInputProps } from "remix-utils/honeypot/server";
import { BottomToast } from "./components/BottomToast";
import { useClientNavigationLinks } from "./hooks/useClientNavigationLinks";
import usePageViews from "./hooks/usePageViews";
import { useTimeoutTrigger } from "./hooks/useTimeoutTrigger";
import { getUserByIdOrThrow } from "./models/user.server";
import rootStyles from "./styles/rootStyles.css?url";
import { authenticator } from "./utils/auth.server";
import { csrf } from "./utils/csrf.server";
import { getEnv } from "./utils/env.server";
import { honeypot } from "./utils/honeypot.server";
import { combineHeaders } from "./utils/misc.server";
import { useNonce } from "./utils/nonce.provider";
import type { Toast } from "./utils/toast.server";
import { getToast } from "./utils/toast.server";
import { mobile } from "./utils/utils.server";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: gestaltStyles },
  { rel: "stylesheet", href: rootStyles },
];

export const meta: MetaFunction = () => {
  const description =
    "Set feature flags and send data to integrations when user interacts.";

  const image =
    "https://res.cloudinary.com/retentionscript/image/upload/f_auot/v1709109577/Retention%20Script%20Platform.png";
  const href = "https://platform.retentionscript.com";
  return [
    {
      title: "Platform | Retention Script",
    },
    {
      name: "description",
      content: description,
    },
    {
      tagName: "link",
      rel: "canonical",
      href,
    },
    { property: "og:title", content: "Retention Script Plaform" },
    {
      property: "og:image",
      content: image,
    },
    {
      property: "og:description",
      content: description,
    },
    { property: "og:type", content: "website" },
    { property: "og:url", content: href },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:domain", content: href.replace("https://", "") },
    {
      property: "twitter:url",
      content: href,
    },
    { property: "twitter:title", content: "Retention Script Platform" },
    { property: "twitter:description", content: description },
    { property: "twitter:image", content: image },
  ];
};

export interface RootData {
  isMobile: boolean;
  csrf: string;
  user?: User | undefined;
  honeypot: HoneypotInputProps;
  toast: Toast | null | undefined;
  ENV: ReturnType<typeof getEnv>;
}

export async function loader({ request }: LoaderFunctionArgs) {
  const data = await authenticator.isAuthenticated(request);
  const [token, commitHeader] = await csrf.commitToken();
  const isMobile = mobile(request);

  const { toast, headers: toastHeaders } = await getToast(request);

  const getData = [data?.userId && getUserByIdOrThrow({ userId: data.userId })]
    .flat()
    .filter(Boolean);

  const [user] = await Promise.all(getData);

  return json<RootData>(
    {
      csrf: token,
      isMobile,
      honeypot: honeypot.getInputProps(),
      toast,
      user: user as User | undefined,
      ENV: getEnv(),
    },
    {
      headers: combineHeaders(
        toastHeaders,
        commitHeader ? { "set-cookie": commitHeader } : null
      ),
    }
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  //TODO: remove this in prod
  console.log({ error });
  return (
    <BannerSlim
      message="Something unexpected happened! Refreshing this page might fix the issue."
      primaryAction={{
        accessibilityLabel: "Contact dev",
        href: "mailto:support@retentionscript.com",
        label: "Contact Developer",
        role: "link",
      }}
      type="error"
      iconAccessibilityLabel="error"
    />
  );
};

export default function App() {
  const nonce = useNonce();
  const loaderData = useLoaderData<typeof loader>();
  const { setState: setToast, state: toast } = useTimeoutTrigger();

  useClientNavigationLinks();
  usePageViews();

  useEffect(() => {
    if (!loaderData.toast) {
      return;
    }
    setToast(true);
  }, [loaderData.toast, setToast]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {toast && loaderData?.toast?.text && (
          <BottomToast
            dismissButton={{
              onDismiss: () => setToast(false),
              accessibilityLabel: "close",
            }}
            text={loaderData?.toast.text}
            type={loaderData?.toast.type}
          />
        )}
        <HoneypotProvider {...loaderData.honeypot}>
          <AuthenticityTokenProvider token={loaderData.csrf}>
            <DeviceTypeProvider
              deviceType={loaderData.isMobile ? "mobile" : "desktop"}
            >
              <Outlet />
            </DeviceTypeProvider>
          </AuthenticityTokenProvider>
        </HoneypotProvider>
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData.ENV)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}
