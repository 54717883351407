import { useLocation, useSearchParams } from "@remix-run/react";
import { useEffect } from "react";
import { analytics } from "../utils/analytics.client";
import { UTM_FRIST, UTM_LAST } from "../utils/constats";

export default function usePageViews() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const utm_source = searchParams.get("utm_source") ?? "retentionscript";
    const utm_medium = searchParams.get("utm_medium") ?? "website";
    const utm_campaign = searchParams.get("utm_campaign") ?? "direct";
    const utm_term = searchParams.get("utm_term");
    const utm_content = searchParams.get("utm_content");

    let utm_first = localStorage.getItem(UTM_FRIST);

    if (!utm_first) {
      utm_first = JSON.stringify({
        first_utm_source: utm_source,
        first_utm_medium: utm_medium,
        first_utm_campaign: utm_campaign,
        first_utm_term: utm_term,
        first_utm_content: utm_content,
      });
      localStorage.setItem(UTM_FRIST, utm_first);
    }

    localStorage.setItem(
      UTM_LAST,
      JSON.stringify({
        last_utm_source: utm_source,
        last_utm_medium: utm_medium,
        last_utm_campaign: utm_campaign,
        last_utm_term: utm_term,
        last_utm_content: utm_content,
      })
    );

    analytics.page({
      ...JSON.parse(utm_first),
      last_utm_source: utm_source,
      last_utm_medium: utm_medium,
      last_utm_campaign: utm_campaign,
      last_utm_term: utm_term,
      last_utm_content: utm_content,
    });
  }, [location.pathname, searchParams]);
}
